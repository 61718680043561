import { Box, Button, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {cbBlue, cbNeutral} from "./styles/cbTheme";

const ErrorDescription = ({ description, showContinue, destinationUrl }) => {

  const styles = {
    button: {
      backgroundColor: cbBlue[500],
      fontSize: '16px',
      margin: "24px 0"
    },
    description: {
      color: cbNeutral[300],
      fontFamily: '"Proxima Nova", sans-serif',
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "32px",
      letterSpacing: "0.11px"
    }
  }

  return (
    <Box
      sx={{ margin: "24px 0" }}
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      justifyItems={"center"}
    >
      <Typography align={"center"} variant={"body1"} style={styles.description}>{description}</Typography>

      {showContinue && destinationUrl != null && (
          <>
            <Typography variant={"body1"} color={cbNeutral[300]}
                        font-family={"Proxima Nova"} font-size={"24px"}
                        font-style={"normal"} font-weight={"400"} line-height={"32px"}
                        letter-spacing={"0.11px"}>Click continue to go directly to the product website.</Typography>
            <a href={destinationUrl}>
              <Button sx={styles.button} align={"center"} variant="contained" color="primary" size="large">
                Continue
              </Button>
            </a>
          </>
      )}
    </Box>
  );
};

ErrorDescription.propTypes = {
  description: PropTypes.string,
  showContinue: PropTypes.bool,
  destinationUrl: PropTypes.string
};

export default ErrorDescription;
