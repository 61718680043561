// Footer.js
import React from "react";
import {Typography} from "@mui/material";
import {cbNeutral} from "./styles/cbTheme";


const styles = {
    footerContainer: {
        display: 'flex',
        borderTop: `1px solid ${cbNeutral[900]}`,
        justifyContent: 'center',
        margin: '25px',
        paddingTop: '25px'
    },
    textContainer: {
        margin: '5px'
    },
    footerText: {
        color: cbNeutral[400],
        fontSize: '14px',
        lineHeight: '17px',
        fontStyle: 'normal',
        letterSpacing: '0.11px',
        fontWeight: 400
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto'
    },
    listContainer: {
        listStyleType: 'none',
        display: 'flex',
        margin: '5px',
    },
    linkGrid: {
        alignItems: 'center',
        lineHeight: '17px'
    },
    line: {
        color: cbNeutral[700],
        borderColor: cbNeutral[700],
        backgroundColor: cbNeutral[700]
    }
}

const Footer = () => {
  return (
      <div className='footer' style={styles.footerContainer}>
          <div className='textContainer' style={styles.textContainer}>
              <Typography sx={styles.footerText}>
                  ClickBank is not a chartered bank or trust company, or depository
                  institution.
              </Typography>
              <Typography sx={styles.footerText}>
                  ClickBank is not authorized to accept deposits or trust accounts and is
                  not licensed or regulated by any state or federal banking authority.
              </Typography>
          </div>
          <div style={styles.linkContainer}>
              <ul style={styles.listContainer}>
                  <li>
                      <a
                          href="https://support.clickbank.com/hc/sections/204406607-Policies"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                          © 2023 ClickBank
                      </a>
                  </li>
                  <li>&nbsp;&nbsp;</li>
                  <li>
                      <a
                          href="https://support.clickbank.com/hc/articles/360004057131-ClickBank-Privacy-Policy"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                          Privacy
                      </a>
                  </li>
                  <li>&nbsp;&nbsp;</li>
                  <li>
                      <a
                          href="https://support.clickbank.com/hc/en-us/articles/115013058268-How-to-Contact-ClickBank"
                          target="_blank"
                          rel="noopener noreferrer"
                      >
                          Contact Us
                      </a>
                  </li>
              </ul>
          </div>
      </div>
  );
};

export default Footer;
