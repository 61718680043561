import { createTheme } from "@mui/material/styles";

export const cbBlue = {
  // primary
  "970": "#F1F3FD",
  "950": "#E8EBFC",
  "900": "#D1D8FA",
  "800": "#A3B1F5",
  "680": "#6C82EF",
  "500": "#2C20DF",
  "320": "#10069F",
  "180": "#090358"
};
export const cbNeutral = {
  // secondary
  "100": "#0E0D26",
  "300": "#3F3D5C",
  "400": "#54527A",
  "700": "#ACABBA",
  "900": "#E3E3E8",
  "950": "#F1F1F4",
  "990": "#FCFCFD",
  "1000": "#FFFFFF"
};
export const cbLime = {
  "170": "#00551E",
  "370": "#A4BF00",
  "870": "#E4F2CC"
};
export const cbTangerine = {
  "250": "#802C00",
  "560": "#F97224",
  "930": "#FFE8D8"
};
export const cbSunshine = {
  "200": "#664600",
  "500": "#FFAF01",
  "850": "#FFE7B3"
};
export const cbOcean = {
  "200": "#00546",
  "400": "#00A9CD",
  "900": "#D4F1F7"
};
export const cbCherry = {
  "280": "#810E1D",
  "500": "#D9263E",
  "950": "#FCE8EB"
};
export const cbLavender = {
  "250": "#551070",
  "400": "#871AB3",
  "900": "#EED2F9"
};
export const palette = {
  common: {
    black: "#000000",
    white: "#FFFFFF"
  },
  primary: {
    light: cbBlue["680"],
    main: cbBlue["500"],
    dark: cbBlue["180"]
  },
  secondary: {
    light: cbNeutral["700"],
    main: cbNeutral["400"],
    dark: cbNeutral["300"]
  },
  error: {
    light: cbCherry["950"],
    main: cbCherry["500"],
    dark: cbCherry["280"]
  },
  info: {
    light: cbTangerine["930"],
    main: cbTangerine["560"],
    dark: cbTangerine["250"]
  },
  warning: {
    light: cbTangerine["930"],
    main: cbTangerine["560"],
    dark: cbTangerine["250"]
  },
  success: {
    light: cbLime["170"],
    main: cbLime["370"],
    dark: cbLime["870"]
  },
  text: {
    primary: cbNeutral["300"],
    secondary: cbNeutral["400"],
    disabled: cbNeutral["700"],
    hint: cbNeutral["300"]
  }
};
export const cbBrand = {
  logoGrey: cbNeutral["700"],
  logoBlue: cbBlue["320"],
  blue: cbBlue["320"],
  border: cbNeutral["700"],
  borderHover: cbNeutral["100"]
};

// Material UI theme with overrides
export const cbTheme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontFamily: '"proxima-nova", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "2.5rem",
      lineHeight: 1.2,
      letterSpacing: "0.11"
    },
    h2: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.25,
      letterSpacing: "0.11"
    },
    h3: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "1.6875rem",
      lineHeight: 1.185,
      letterSpacing: "0.11"
    },
    h4: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.333,
      letterSpacing: "0.11"
    },
    h5: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "1.375rem",
      lineHeight: 1.09,
      letterSpacing: "0.11"
    },
    h6: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "1.1875rem",
      lineHeight: 1.263,
      letterSpacing: "0.11"
    },
    subtitle1: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.11"
    },
    subtitle2: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: "0.11"
    },
    body1: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 400,
      fontSize: "1.125rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.11"
    },
    body2: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.43,
      letterSpacing: "0.11"
    },
    button: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 600,
      fontSize: "1.0625rem",
      lineHeight: 1.235,
      letterSpacing: "0.11",
      textTransform: "none"
    },
    caption: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: "0.11"
    },
    overline: {
      fontFamily: '"proxima-nova", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: "0.11",
      textTransform: "uppercase"
    }
  },
  overrides: {
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: "0"
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        "&$expanded": {
          borderTop: `1px solid ${cbNeutral["900"]}`
        },
        "&:hover": {
          backgroundColor: cbBlue["950"]
        }
      }
    },
    MuiAccordionDetails: {
      root: {
        padding: "16px"
      }
    },
    MuiPaper: {
      root: {
        color: `${cbNeutral["300"]}`
      },
      elevation1: {
        boxShadow: "none"
      }
    },
    MuiCollapse: {
      entered: {
        borderBottom: `1px solid ${cbNeutral["900"]}`
      }
    },
    MuiTableRow: {
      root: {
        "&$hover:hover": {
          backgroundColor: cbBlue[950]
        }
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "rgb(0, 117, 255)",
          backgroundColor: "transparent !important"
        }
      }
    },
    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    }
  }
});
