import React from "react";
import ErrorComponent from "./ErrorComponent";

// Main App component
function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const errorCode = queryParams.get("errCode");
  const destinationUrl = queryParams.get("destinationUrl");

  return (
    <ErrorComponent errorCode={errorCode} destinationUrl={destinationUrl} />
  );
}

export default App;
