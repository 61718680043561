import React from "react";
import PropTypes from "prop-types";
import ErrorPage from "./ErrorPage";

// Functional component to display the error message
const ErrorComponent = ({ errorCode, destinationUrl }) => {
  // Define error messages based on errorState
  const errorMessages = {
    invalidtarget: {
      header: "Link Error",
      description: "The destination URL is invalid. Please contact the seller.",
      details:
        "The link you clicked contains seller information that is invalid or missing."
    },
    samenicknames: {
      header: "Something went wrong",
      showContinue: true,
      details:
        "The link you clicked contain affiliate and seller information that is the same."
    },
    invalidsite: {
      header: "Link Error",
      description: "The destination URL is invalid. Please contact the seller."
    },
    invalidnickname: {
      header: "Something went wrong",
      showContinue: true,
      details: "The link you clicked contains invalid affiliate information."
    },
    invalidvendornickname: {
      header: "Link Error",
      description: "The destination URL is invalid",
      details:
        "The link you clicked contains seller information that is invalid or missing."
    },
    accntstate: {
      header: "Something went wrong",
      showContinue: true,
      details:
        "The link you clicked was created by a ClickBank affiliate that is not currently active."
    },
    afblacklisted: {
      header: "Something went wrong",
      showContinue: true,
      details:
        "The link you clicked was created by a ClickBank affiliate that has been blocked from" +
        " promoting this product."
    },
    nowhitelist: {
      header: "Something went wrong",
      showContinue: true,
      details:
        "The link you clicked was created by a ClickBank affiliate that has not been approved to" +
        " promote this product."
    },
    blacklisted: {
      header: "Link Error",
      details:
        "The seller of this product is not currently in service."
    },
    vendoraccntstate: {
      header: "Link Error",
      description: "The seller of this product is not currently in service."
    },
    haltedsales: {
      header: "Link Error",
      description: "The seller of this product is not currently in service."
    }
  };

  // Display the appropriate error message or a default message
  const errorMessage = errorMessages[errorCode != null ? errorCode.toLowerCase() : ''] || {
    header: "Link Error",
    description: "An error occurred"
  };

  return (
    <ErrorPage
      showContinue={errorMessage.showContinue}
      header={errorMessage.header}
      destinationUrl={destinationUrl}
      description={errorMessage.description}
      details={errorMessage.details}
    />
  );
};

ErrorComponent.propTypes = {
  errorCode: PropTypes.string,
  destinationUrl: PropTypes.string
};

export default ErrorComponent;
