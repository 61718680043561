// ErrorPage.js

import React from "react";
import Footer from "./Footer";
import { Grid, Typography } from "@mui/material";
import ErrorDescription from "./ErrorDescription";
import PropTypes from "prop-types";
import {cbNeutral} from "./styles/cbTheme";

const styles = {
  header: {
    color: cbNeutral[100],
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "48px",
    letterSpacing: "0.11px"
  },
  details: {
    color: cbNeutral[100],
    fontFamily: '"Proxima Nova", sans-serif',
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.11px",
    align: "center",
    justifyContent: "center"
  },
  container: {
    height: "100vh",
    width:'100%'
  }
};

const ErrorPage = ({
  showContinue,
  header,
  description,
  destinationUrl,
  details
}) => {
  return (
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        sx={styles.container}
      >
        <Grid item>
          <Grid
            sx={{ minHeight: "50vh", height: "444px", width: "800px" }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={"backgroundImage"}
          >
            <Grid item>
              <Typography variant={"h1"} style={styles.header}>{header}</Typography>
            </Grid>
            <Grid item>
              <ErrorDescription
                showContinue={showContinue}
                description={description}
                destinationUrl={destinationUrl}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography align="center" variant={"body2"} style={styles.details}>{details}</Typography>
          </Grid>
        </Grid>
      </Grid>
        <Footer />
    </div>
  );
};

ErrorPage.propTypes = {
  showContinue: PropTypes.bool,
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  destinationUrl: PropTypes.string,
  details: PropTypes.string
};

export default ErrorPage;
